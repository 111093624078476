<header>
  <h1>JAGA MORAR FAMILY</h1>
  <nav>
    <a href="#">Home</a>
    <a href="#">Calendar</a>
    <a href="#">Family Tree</a>

  </nav>

</header>
<app-calendar></app-calendar>



<router-outlet></router-outlet>